import React from "react"

import Layout from "../components/layout"
import ContactUs from "../components/ContactUs"
import PageHeader from "../components/PageHeader"
import { AboutImage } from "../components/background/BackgroundImages"

const About = () => (
  <Layout>
    <PageHeader title="About VoerEir" />
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-center">
            <div className="img-wrap  box-bottom-right width-100">
              <AboutImage />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Our Story</h2>
            </div>
            {/*<p className="paragraph-3">
              Around 2012, the telecom operators decided to move away from 
              vertical and monolithic network nodes that were fully supplied by 
              one vendor (application, operating system, and hardware) to 
              <strong> Network Function Virtualization (NFV) </strong>where multiple applications 
              from different vendors would share the operating system and hardware resources. 
              The goal was to achieve Capex savings due to economies of scale of shared 
              resources, as well as the speed of implementation when adding new network 
              functions and applications. But it has been a challenge for the industry to 
              fully achieve the benefits of saving and speed that NFV promise due to the 
              increased complexity to properly test and configure virtualized 
              applications (VNFs) on commodity hardware.
            </p>*/}
            {/*<p className="paragraph-3">
              Network Function Virtualization Infrastructure <strong> (NFVI) </strong>
              is today typically based <strong> on OpenStack </strong> and Virtual 
              Machines and it is starting to adopt <strong> Kubernetes </strong> 
              containers. An NFVI solution is a quite complex solution, containing 
              software from multiple Opensource 
              communities, and hardware from many different vendors. Solution 
              integration is, therefore, a substantial task, and the resulting 
              Infrastructures can have quite a significant variation both in 
              functionality and performance characteristics.
          </p>*/}
            <p className="paragraph-3 mb-4">
            In 2016, a visionary team of executives from the telecom industry came together to establish VoerEir, a startup named after the Norse goddesses of wisdom (Vör) and healing (Eir).
            Their primary focus was addressing the complex issues related to NFVI testing and VNF onboarding.
            Since then, VoerEir has dedicated substantial resources to research and development, resulting in the creation of their cutting-edge software product known as "Touchstone." 
            VoerEir helps telecom operators identify, 
            fix and eliminate the pain points in their telco cloud environments, and enables the initial vision of NFV. 
            This vision also includes testing Openstack and Kubernetes environments and deployments using Private Clouds or Public Clouds.</p>
              <p className="paragraph-3 mb-4">
              VoerEir has garnered immense recognition and trust from numerous prominent carriers in the industry. 
              These leading organizations highly value VoerEir as a credible and dependable partner in facilitating 
              the transformation from traditional legacy solutions to cutting-edge cloud-based NFV architecture. 
              VoerEir's expertise extends beyond telecommunications, encompassing both Telco and non-Telco applications, 
              seamlessly integrating with any cloud platform from any vendor.
            </p>
          </div>
        </div>
      </div>
    </section>
    
    
    <ContactUs />
  </Layout>
)

export default About
